import { createStore } from 'vuex'

import modules from './modules'
import router from '@/router'
import {
  getAdminFromToken,
  getIsEnabledFromToken,
  getIsProfileImgFromToken,
  getNameFromToken,
  getPlayerIDFromToken, getUserIDFromToken
} from "@/helpers/roles";
import moment from "moment/moment";

const store = createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {
      dpsOptions: JSON.parse(localStorage.getItem('dpsOptions')),
      currentUser: JSON.parse(localStorage.getItem('dpsAuthUser')),
      seasonID: 0,
      seasonEnded: false,
      userID: null,
      name: null,
      isProfileImg: false,
      playerID: null,
      isAdmin: false,
      isEnabled: false,
      playerStates: [],
      leagueSeasons: [],
    }
  },
  mutations: {
    SET_CURRENT_USER(state, newValue) {
      state.currentUser = newValue
      saveState('currentUser', newValue)
      state.userID = newValue != null ? getUserIDFromToken() : null;
      state.name = newValue != null ? getNameFromToken() : null;
      state.isProfileImg = newValue != null ? getIsProfileImgFromToken() : false;
      state.playerID = newValue != null ? getPlayerIDFromToken() : null;
      state.isAdmin = newValue != null ? getAdminFromToken() : false;
      state.isEnabled = newValue != null ? getIsEnabledFromToken() : false;
    },
    setUserID(state, id) {
      state.userID = id
    },
    setName(state, name) {
      state.name = name
    },
    setIsProfileImg(state, isProfileImg) {
      state.isProfileImg = isProfileImg
    },
    setPlayerID(state, id) {
      state.playerID = id
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin
    },
    setIsEnabled(state, isEnabled) {
      state.isEnabled = isEnabled
    },
    setSeason(state, season) {
      state.seasonID = season.id;
      state.seasonEnded = season.dateend != null ? moment(season.dateend, 'YYYY-MM-DD').isBefore(moment()) : false;
    },
    setPlayerStates(state, list) {
      state.playerStates = list
    },
    setLeagueSeasons(state, list) {
      state.leagueSeasons = list
    }
  },
  getters: {
    loggedIn(state) {
      return state.currentUser != null && state.currentUser.token != null
    },
    getAuthToken(state) {
      return state.currentUser != null ? state.currentUser.token : null;
    },
    userID(state) {
      return state.userID != null ? state.userID : -1
    },
    name(state) {
      return state.name != null && state.name
    },
    isProfileImg(state) {
      return state.isProfileImg != null && state.isProfileImg
    },
    isEnabled(state) {
      return state.isEnabled != null && state.isEnabled
    },
    isAdmin(state) {
      return state.isAdmin != null && state.isAdmin
    },
    isLeaguePlayer(state) {
      return state.playerID != null && state.playerID > -1
    },
    playerID(state) {
      return state.playerID != null ? state.playerID : -1
    },
    seasonID(state) {
      return state.seasonID != null ? state.seasonID : 0
    },
    seasonEnded(state) {
      return state.seasonEnded
    },
    getLayoutMode(state) {
      return state.dpsOptions != null && state.dpsOptions.layoutMode != null ? state.dpsOptions.layoutMode : null;
    }
  },
  actions: {
    logIn({commit}, payload) {
      commit('SET_CURRENT_USER', payload)
    },
    logOut({ commit }) {
      commit('SET_CURRENT_USER', null)
    },
    validateToken({commit, getters}) {
      let token = getters.getAuthToken;
      if (token) {
        return fetch(process.env.VUE_APP_AUTH_ENDPOINT, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "token": token
          })
        })
            .then(async response => {
              if (!response.ok) {
                commit("SET_CURRENT_USER", null);
                await router.push({name: 'login', query: {redirectFrom: router.currentRoute.value.fullPath, expired: 1}});
                return Promise.reject();
              }
              commit("setUserID", getUserIDFromToken());
              commit("setName", getNameFromToken());
              commit("setIsProfileImg", getIsProfileImgFromToken());
              commit("setPlayerID", getPlayerIDFromToken());
              commit("setIsAdmin", getAdminFromToken());
              commit("setIsEnabled", getIsEnabledFromToken());
            })
            .catch(() => {
              commit("SET_CURRENT_USER", null);
              localStorage.removeItem('dpsAuthUser');
            })
      }
    },
    fetchPlayerStates({commit}) {
      return fetch(process.env.VUE_APP_API_ENDPOINT + '?type=playerstatus', {
        method: 'get',
        headers: {
          'content-type': 'application/json'
        }
      })
          .then(res => {
            if (!res.ok) {
              const error = new Error(res.statusText);
              error.json = res.json();
              throw error;
            }

            return res.json();
          })
          .then(json => {
            commit("setPlayerStates", json.data);
          })
          .catch(err => {
            console.log (err);
            this.error = err;
            if (err.json) {
              return err.json.then(json => {
                this.error.message = json.message;
              });
            }
          })
    },
    fetchLeagueSeasons({commit}) {
      return fetch(process.env.VUE_APP_API_ENDPOINT + '?type=seasons&actual', {
        method: 'get',
        headers: {
          'content-type': 'application/json'
        }
      })
          .then(res => {
            if (!res.ok) {
              const error = new Error(res.statusText);
              error.json = res.json();
              throw error;
            }

            return res.json();
          })
          .then(json => {
            commit("setLeagueSeasons", json.data);
            if (json.data.length > 0) {
              commit("setSeason", json.data[0]);
            }
          })
          .catch(err => {
            console.log (err);
            this.error = err;
            if (err.json) {
              return err.json.then(json => {
                this.error.message = json.message;
              });
            }
          })
    },
  }
})

export default store

function saveState(key, state) {
  window.sessionStorage.setItem(key, JSON.stringify(state))
}