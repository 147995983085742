import store from "@/state/store";
import router, {loginRedirectException} from "@/router/index";

export default [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Domov",
      authRequired: false,
    },
    component: () => import("../views/dps/Home"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/dps/users/login.vue"),
    meta: {
      title: "Prihlásenie",
      beforeResolve(routeTo, routeFrom, next) {
        // If the users is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          if (routeTo.query.redirectFrom) {
            next()
          } else {
            let params = {redirectFrom: loginRedirectException.includes(routeFrom.fullPath) ? '/' : routeFrom.fullPath}
            if (routeTo.query.expired) {
              params.expired = 1;
            }
            next({path: routeTo.path, query: params})
          }
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/dps/Home"),
    meta: {
      title: "Odhlásenie", authRequired: true,
      beforeResolve() {
        localStorage.removeItem('dpsAuthUser');
        store.dispatch('logOut');
        router.push('/');
      },
    },
  },

  {
    path: "/register",
    name: "register",
    component: () => import("../views/dps/users/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters.loggedIn) {
          next({ name: "home" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgot-login",
    name: "forgot-login",
    component: () => import("../views/dps/users/forgot-login.vue"),
    meta: {
      title: "Zabudnuté prihlasovacie meno",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "default" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/dps/users/forgot-password.vue"),
    meta: {
      title: "Zabudnuté heslo",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "default" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/activation",
    name: "activation",
    component: () => import("../views/dps/users/activation.vue"),
    meta: {
      title: "Aktivácia konta",
      authRequired: false,
    },
  },
  {
    path: "/resetpwd",
    name: "resetpwd",
    component: () => import("../views/dps/users/reset-password.vue"),
    meta: {
      title: "Obnova hesla",
      authRequired: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/dps/users/profile.vue"),
    meta: {
      title: "Profil",
      authRequired: true,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/dps/users/password-change.vue"),
    meta: {
      title: "Zmena hesla",
      authRequired: true,
    },
  },

  {
    path: "/about",
    name: "about",
    meta: {
      title: "O klube",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/about"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Kontakt",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/contacts"),
  },
  {
    path: "/members",
    name: "members",
    meta: {
      title: "Členovia",
      authRequired: false,
    },
    component: () => import("../views/dps/members"),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "Spravodajstvo",
      authRequired: false,
    },
    component: () => import("../views/dps/news/news.vue"),
  },
  {
    path: "/newsdetail/:id?",
    name: "newsdetail",
    meta: {
      title: "Spravodajstvo",
      authRequired: false,
    },
    component: () => import("../views/dps/news/newsdetail.vue"),
  },
  {
    path: "/news/:mode/:id?",
    name: "newsedit",
    meta: {
      title: "Spravodajstvo",
      authRequired: false,
    },
    component: () => import("../views/dps/news/newsedit.vue"),
  },
  {
    path: "/albums/:id?",
    name: "albums",
    meta: {
      title: "Fotky",
      authRequired: false,
    },
    component: () => import("../views/dps/albums"),
  },
  {
    path: "/album/:id?",
    name: "album",
    meta: {
      title: "Fotky",
      authRequired: false,
    },
    component: () => import("../views/dps/album"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Používatelia",
      authRequired: true,
    },
    component: () => import("../views/dps/users/Users"),
  },
  {
    path: "/tournaments",
    name: "tournaments",
    meta: {
      title: "Turnaje",
      authRequired: false,
    },
    component: () => import("../views/dps/tournaments/Tournaments"),
  },
  {
    path: "/beginners/:id?",
    name: "beginners",
    meta: {
      title: "Turnaje začiatočníkov",
      authRequired: false,
    },
    component: () => import("../views/dps/tournaments/Beginners.vue"),
  },
  {
    path: "/links",
    name: "links",
    meta: {
      title: "Odkazy",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/links"),
  },

  {
    path: "/league/profile",
    name: "leagueProfile",
    meta: {
      title:  "Ligy - Ligový profil",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/Profile"),
  },
  {
    path: "/league/statuses",
    name: "leagueStatuses",
    meta: {
      title: "Ligy - Stavy",
      authRequired: true,
    },
    component: () => import("../views/dps/leagues/Statuses"),
  },
  {
    path: "/league/seasons",
    name: "leagueSeasons",
    meta: {
      title:  "Ligy - Sezóny",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/Seasons"),
  },
  {
    path: "/league/list",
    name: "leagueList",
    meta: {
      title: "Ligy - Zoznam",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/Leagues"),
  },
  {
    path: "/league/players",
    name: "leaguePlayers",
    meta: {
      title: "Ligy - Hráči",
      authRequired: true,
    },
    component: () => import("../views/dps/leagues/Players"),
  },
  {
    path: "/league/teams",
    name: "leagueTeams",
    meta: {
      title: "Ligy - Teamy",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/Teams"),
  },
  {
    path: "/league/tables",
    name: "leagueTables",
    meta: {
      title: "Ligy - Tabuľky",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/Tables"),
  },
  {
    path: "/league/playerlists",
    name: "leaguePlayerLists",
    meta: {
      title: "Ligy - Súpisky",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/PlayerLists"),
  },
  {
    path: "/league/calendar",
    name: "leagueCalendar",
    meta: {
      title: "Ligy - Kalendár",
      authRequired: false,
    },
    component: () => import("../views/dps/leagues/Calendar"),
  },
  {
    path: "/league/current",
    name: "leagueCurrent",
    meta: {
      title: "Ligy - Aktuálne",
      authRequired: true,
    },
    component: () => import("../views/dps/leagues/CurrentMatches.vue"),
  },
  {
    path: "/league/matchhistory/:id",
    name: "matchhistory",
    meta: {
      title: "Zobraz históriu prihlásení",
      authRequired: true,
    },
    component: () => import("../views/dps/leagues/MatchHistory.vue"),
  },


  {
    path: "/trainings",
    name: "trainings",
    meta: {
      title: "Tréningy",
      authRequired: false,
    },
    component: () => import("../views/dps/trainings/trainings.vue"),
  },
  {
    path: "/trainings/materials",
    name: "trainingMaterials",
    meta: {
      title: "Tréningové materiály",
      authRequired: false,
    },
    component: () => import("../views/dps/trainings/materials"),
  },

  {
    path: "/archive",
    name: "archive",
    meta: {
      title: "Archív",
      authRequired: false,
    },
    component: () => import("../views/dps/tournaments/Archive"),
  },
  {
    path: "/archive/show/:id",
    name: "showarchive",
    meta: {
      title: "Zobraz archív",
      authRequired: false,
    },
    component: () => import("../views/dps/tournaments/ShowArchive"),
  },
  {
    path: "/documents",
    name: "documents",
    meta: {
      title: "Dokumenty",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/documents"),
  },
  {
    path: "/upload",
    name: "upload",
    meta: {
      title: "Upload",
      authRequired: true,
    },
    component: () => import("../views/dps/commons/upload"),
  },
  {
    path: "/objectdocuments",
    name: "objectdocuments",
    meta: {
      title: "Prílohy",
      authRequired: true,
    },
    component: () => import("../views/dps/commons/objectdocuments"),
  },

  {
    path: "/codepages/document",
    name: "codepagesDocument",
    meta: {
      title: "Číselníky - Kategórie dokumentov",
      authRequired: true,
    },
    component: () => import("../views/dps/codepages/codepage-document"),
  },
  {
    path: "/codepages/link",
    name: "codepagesLink",
    meta: {
      title: "Číselníky - Kategórie odkazov",
      authRequired: true,
    },
    component: () => import("../views/dps/codepages/codepage-link"),
  },
  {
    path: "/codepages/training",
    name: "codepagesTraining",
    meta: {
      title: "Číselníky - Kategórie tr. materiálov",
      authRequired: true,
    },
    component: () => import("../views/dps/codepages/codepage-training"),
  },
  {
    path: "/codepages/archive",
    name: "codepagesArchive",
    meta: {
      title: "Číselníky - Kategórie archívu",
      authRequired: true,
    },
    component: () => import("../views/dps/codepages/codepage-archive.vue"),
  },
  {
    path: "/codepages/beginners",
    name: "codepagesBeginners",
    meta: {
      title: "Číselníky - Turnaj začiatočníkov",
      authRequired: true,
    },
    component: () => import("../views/dps/codepages/codepage-beginners.vue"),
  },

  {
    path: "/gdpr",
    name: "gdpr",
    meta: {
      title: "Ochrana a spracovaníe osobných údajov",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/gdpr.vue"),
  },

  {
    path: "/polls",
    name: "polls",
    meta: {
      title: "Ankety",
      authRequired: false,
    },
    component: () => import("../views/dps/polls/polls.vue"),
  },
  {
    path: "/polls/admin",
    name: "polllist",
    meta: {
      title: "Administrácia ankiet",
      authRequired: false,
    },
    component: () => import("../views/dps/polls/admin/poll.vue"),
  },
  {
    path: "/pollitems",
    name: "pollitems",
    meta: {
      title: "Anketa - odpovede",
      authRequired: true,
    },
    component: () => import("../views/dps/polls/admin/pollitems.vue"),
  },
  {
    path: "/pollresults",
    name: "pollresults",
    meta: {
      title: "Anketa - výsledky",
      authRequired: true,
    },
    component: () => import("../views/dps/polls/admin/pollresults.vue"),
  },

  {
    path: "/mobile",
    name: "mobile",
    meta: {
      title: "Mobilná aplikácia",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/mobile.vue"),
  },
  {
    path: "/versions",
    name: "versions",
    meta: {
      title: "Verzie",
      authRequired: false,
    },
    component: () => import("../views/dps/commons/versions.vue"),
  },
  {
    path: "/statictextedit/:type",
    name: "statictextedit",
    meta: {
      title: "Text",
      authRequired: true,
    },
    component: () => import("../views/dps/commons/StaticTextEdit.vue"),
  },
];