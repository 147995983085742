
<template>
  <router-view v-if="seasonID > 0" :key="$route.path"></router-view>
  <div v-if="seasonID === 0" class="text-center fs-16" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
    <b-spinner variant="primary" /><br/>{{ $t('page.loading') }}
  </div>

  <div id="cookieConfirm" v-if="!isCookiesAccepted">
    <div class="text">{{$t('cookies.text')}}</div>
    <div class="action">
      <b-button variant="primary" class="btn btn-primary waves-effect waves-light ms-3" @click="setCookiesAccepted">{{$t('cookies.accept')}}</b-button>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import {onMounted} from "vue";
import { useStore } from 'vuex'
import i18n from "@/i18n";

export default {
  name: 'App',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onMounted(async () => {
      if (route.path != '/activation') {
        await store.dispatch("validateToken");
      }
      await store.dispatch("fetchLeagueSeasons");
      await store.dispatch("fetchPlayerStates");
      await router.isReady();
    })
  },
  data() {
    return {
      isCookiesAccepted: localStorage.getItem('dpsCookies') === '1',
    };
  },
  computed: {
    seasonID() {
      return this.$store ? this.$store.getters.seasonID : 0;
    },
  },
  created() {
    if (this.$store.getters.getLayoutMode != null) {
      this.$store.dispatch("layout/changeMode", { mode: this.$store.getters.getLayoutMode });
    }
    if (localStorage.getItem('dpsLanguage') != null) {
      i18n.global.locale = localStorage.getItem('dpsLanguage');
    }
  },
  methods: {
    setCookiesAccepted() {
      localStorage.setItem('dpsCookies', '1');
      this.isCookiesAccepted = true;
    },
  }

}
</script>


