import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import Maska from 'maska';
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";
import FloatingVue from 'floating-vue'
import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import VueGtag from "vue-gtag";

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

let app = createApp(App)

app.config.globalProperties.$appURL = process.env.VUE_APP_URL
app.config.globalProperties.$auth = process.env.VUE_APP_AUTH_ENDPOINT
app.config.globalProperties.$activation = process.env.VUE_APP_ACTIVATION_ENDPOINT
app.config.globalProperties.$resetpwd = process.env.VUE_APP_RESETPWD_ENDPOINT
app.config.globalProperties.$api = process.env.VUE_APP_API_ENDPOINT
app.config.globalProperties.$apiForm = process.env.VUE_APP_APIFORM_ENDPOINT

app.config.globalProperties.$documentsPath = process.env.VUE_APP_DOCUMENTS_PATH
app.config.globalProperties.$newsPath = process.env.VUE_APP_NEWS_PATH
app.config.globalProperties.$photosPath = process.env.VUE_APP_PHOTOS_PATH
app.config.globalProperties.$tournamentsPath = process.env.VUE_APP_TOURNAMENTS_PATH
app.config.globalProperties.$trainingsPath = process.env.VUE_APP_TRAININGS_PATH
app.config.globalProperties.$filesPath = process.env.VUE_APP_FILES_PATH
app.config.globalProperties.$archivePath = process.env.VUE_APP_ARCHIVE_PATH

app.config.globalProperties.$tinyMceAPIKey = '6ztcxl33dcwcr10v1oergqgx1b8ixknu7i794wqv6u6tr76h'

app.use(store)
    .use(router)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(FloatingVue, {
        themes: {
            tooltip: {
                html: true,
            }
        }
    })
    .use(VueGtag, {
        config: {
            id: "G-QCX7QWWBE5",
        },
    }, router)
    .use(i18n)
    .use(vClickOutside).mount('#app')