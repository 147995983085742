import store from '@/state/store'
import jwt_decode from "jwt-decode";

export const roles = { LEAGUES: 1, USERS: 2, NEWS: 3, TOURNAMENTS: 4, DOCUMENTS: 5, POLLS: 6, NOTICES: 7, FEES: 8, BEGINNERS: 9, OTHERS: 20 }

export function hasRole(role) {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return false;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return (decoded.roles && decoded.roles.includes(role)) || getAdminFromToken();
}

export function hasAdminRole() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return false;
    }
    return getAdminFromToken();
}

export function getUserIDFromToken() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return null;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return decoded.userID;
}

export function getPlayerIDFromToken() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return null;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return decoded.playerID;
}

export function getNameFromToken() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return null;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return decoded.name;
}

export function getIsProfileImgFromToken() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return null;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return decoded.img;
}

export function getAdminFromToken() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return false;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return decoded.isAdmin;
}

export function getIsEnabledFromToken() {
    if (!store.getters.loggedIn || !store.getters.getAuthToken) {
        return null;
    }
    let decoded = jwt_decode(store.getters.getAuthToken);
    return decoded.e;
}
